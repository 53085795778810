<template>
    <div class="flex flex-col p-4 h-full overflow-hidden">

        <div class="flex items-center mb-2 h-auto">

            <div class="module-title">
                Project Management
            </div>

            <div class="ml-auto flex relative">
                <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
                <!-- <router-link  v-if="globalParamsPretty.rol.role_type_name !== 'EP'" class="header-btn" :to="'ProjectsSLSMU'"> -->
                <div class="header-btn" @click="goto('ProjectsSLSMU')">
                    <i class="mdi mdi-view-split-vertical"></i>
                </div>
                <!-- </router-link> -->
                <router-link v-if="billing" class="header-btn-gray" :to="{ name: 'home' }">
                    <i class="mdi mdi-home"></i>
                </router-link>
                <router-link v-else class="header-btn" :to="'ProjectsBilling'">
                    <i class="mdi mdi-view-list"></i>
                </router-link>
            </div>

        </div>

        <div class="flex-1 min-h-0 overflow-hidden flex flex-col">

            <div class="overflow-hidden flex flex-1 min-h-0 mb-2">

                <div class="flex overflow-hidden mr-1 flex flex-col w-4/6 mr-1 relative h-full">
                    <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
                    <Ner class="h-full" />
                </div>

                <div class="box w-full overflow-hidden ml-1 flex flex-col relative h-full">
                    <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
                    <div class="box-title">
                        Margin
                    </div>
                    <div class="flex flex-1 min-h-0 overflow-hidden mb-5 relative">
                        <margin-average />
                    </div>
                </div>

            </div>

            <div class="overflow-hidden flex-none flex mb-2 relative" :class="billing ? 'flex-row h-24' : 'h-32'">

                <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->

                <tnrnew v-if="rolestnr" class="h-32 flex-none"/>
                <rate class="h-32" :class="{'w-full': !rolestnr}"/>

            </div>

            <div class="overflow-hidden flex-none flex relative" :class="billing ? 'h-84 flex-col' : 'h-48'">

                <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->

                <billing :billing="billing" />

                <revenue-days :billing="billing" />

            </div>
            
        </div>

    </div>

</template>

<script>
    import { state } from '@/store';

    const Ner = () => import('@/components/Desktop/ProjectsManagement/Ner.vue');
    const Tnr = () => import('@/components/Desktop/ProjectsManagement/Tnr.vue');
    const Billing = () => import('@/components/Desktop/ProjectsManagement/Billing.vue');
    const Tnrnew = () => import('@/components/Desktop/ProjectsManagement/Tnrnew.vue');
    const revenueDays = () => import('@/components/Desktop/ProjectsManagement/RevenueDays.vue');
    const Rate = () => import('@/components/Desktop/ProjectsManagement/Rate.vue');

    export default {
      
        components: {
            Ner,
            Tnr,
            Billing,
            revenueDays,
            Tnrnew,
            Rate
        },
        props: ['billing'],
        methods:{
            goto(routename){
                this.$router.push( {name:routename} )
            }
        },
        computed: {
            globalParamsPretty(){ 
                return state.globalParamsPretty 
            },
            userData(){ 
                return state.user 
            },
            defaultSelectedRol() {
                return this._.find(this.userData.roles, {
                    role_type_id: this.userData.role_default.role_type_id,
                    gui_role_specific_id: this.userData.role_default.gui_role_specific_id,
                })
            },
            rolSelected(){
                return state.rolSelected
            },
            rolestnr(){
                if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                    return true
                } else {
                    return false
                }
            },
            seeit(){
                if(state.rolSelected.role_type_link != 'presidencia'){
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>