<template>

    <div class="box overflow-hidden relative">

        <div v-if="view !== 'mobile'" class="box-title">Last updates</div>

        <loader :loading="loading" />

        <div v-if="view === 'home'" class="h-full overflow-auto relative pr-2">
            <div 
                v-for="(update, index) in updates" 
                :key="index"
                class="h-24 w-full flex-none box bg-body m-2 flex flex-row overflow-hidden cursor-pointer"
                @click="goto(update.model_type, update.id)"
            >
                <div :class="'rounded-lg w-1 bg-' + color(update.model_type) + ' h-full mr-2'"></div>
                <div class="h-24 flex-1 flex flex-col justify-start items-start overflow-hidden">
                    <div class="flex-1 flex flex-col justify-start items-start overflow-hidden">
                        <span class="mb-1 text-lg font-semibold">{{ model(update.model_type) }}</span>
                        <span class="text-xxs text-warning mb-1">
                            {{ update.date | moment('DD/MM/YYYY') }}
                        </span>
                        <span class="text-sm">{{ update.title }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="view !== 'mobile'" class="h-30 w-full overflow-x-scroll flex no-scrollbar -mx-1 pt-5">
            
            <swiper ref="homeMenu" :options="swiperOptions" class="w-full">

                <swiper-slide v-for="(update, index) in updates" :key="index" class="px-1">
                    <div class="w-full h-24 box bg-body flex flex-row overflow-hidden cursor-pointer"
                        @click="goto(update.model_type, update.id)">
                        <div class="mx-1 w-1 h-full rounded-lg"
                            :class="'bg-' + color(update.model_type)"></div>
                        <div class="ml-2 py-1 flex-1 min-w-0">
                            <h3 class="text-lg truncate">{{ model(update.model_type) }}</h3>
                            <p class="mt-1 text-xs text-warning">
                                {{ update.date | moment('MM/DD/YYYY') }}
                            </p>
                            <p class="mt-2 text-sm text-font-gray truncate-2">{{ update.title }}</p>
                        </div>
                    </div>
                </swiper-slide>
                
            </swiper>
        
        </div>

        <div v-else class="h-full overflow-auto mobile-scrollbar">
            <!-- <div class="scroll-gradient"></div> -->
            <div v-for="(update, index) in updates" :key="index"
                class="h-20 w-full flex-none box bg-body p-2 mb-2 flex flex-row overflow-hidden"
                @click="gotoMobile(update.model_type, update.id)"
            >
                <div :class="'rounded-lg w-1 bg-' + color(update.model_type) +' h-full mr-2'"></div>
                <div class="h-20 flex-1 flex flex-col justify-start items-start overflow-hidden">
                    <div class="flex-1 flex flex-col justify-start items-start overflow-hidden">
                        <span class="mb-1 text-xs font-semibold  truncate-1">{{ model(update.model_type) }}</span>
                        <span class="text-xxs text-warning mb-1">
                            {{ update.date | moment('DD/MM/YYYY') }}
                        </span>
                        <span class="text-xxs truncate-3">{{ update.title }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import {state} from '@/store';

export default {
    props: ['view'],
    data() {
        return {
            loading: false,
            updates: [],
            swiperOptions: {
                slidesPerView: 4,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false
                },
                // spaceBetween: 30,
                // grabCursor: true,
            },
            interval: false
        }
    },
    methods: {
        goToSections(update){ 
            return this.$goToSection(update) 
        },
        load() {
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=LastUpdate', {params:state.globalParams})
                .then(response => {
                    this.updates  = _.remove(response.data, function(elem) {
                        return elem.active === '1';
                    });
                    this.loading = false
                })
        },
        color(type){ 
            return this.$colorsByOptionCommsAndDash(type)
        },
        model(key){ 
            return this.$typeModel(key) 
        },
        goto(route, params) {
            this.$router.push( { name: route, params: { id: params } })
        },
        gotoMobile(route, params) {
            const routes = {
                'TheEssential': 'TheEssentialMobile',
                'EYEvents': 'EventsMobile',
                'Alerts': 'AlertsMobile',
                'Wins': 'WinsMobile',
                'TipsAndDocs': 'Tipsanddocs',
                'CommAndPodcast': 'CommonsPodcastsMobile'
            };

            this.$router.push( { name: routes[route], params: { id: params } })
        }
    },
    computed:{
        howSlides(){

            var slidesNumber = 5.5

            if(this.updates.length < 5){

                slidesNumber = this.updates.length
            
            } 

            return slidesNumber

        }
    },
    mounted() { 
        this.load() 
    
        this.interval = setInterval(() => {
            this.load();
        }, state.refreshPersonalDashboard);

    },
    beforeDestroy(){
        clearInterval(this.interval);
    }
}
</script>