<template>

    <div class="w-1/5 overflow-hidden p-4 pr-4 flex flex-col">
        <div class="flex items-center mb-2">
            <div class="module-title ">Comms Dashboard</div>
            <div class="ml-auto flex">
                <router-link :to="{ name: 'PersonalDashboard' }" class="header-btn">
                    <i class="mdi mdi-view-dashboard-variant"></i>
                </router-link>
            </div>
        </div>
        <div class="h-full overflow-hidden flex flex-col">
            <top-scores class="h-1/5" />
            <div class="box-title mt-4 mb-2"></div>
            <last-updates class="h-2/5" view="home" />
            <div class="box-title mt-4 mb-1"></div>
            <out-standing class="h-2/5 "/>
        </div>
    </div>

</template>

<script>
import LastUpdates from '@/components/Globals/PersonalDashboard/LastUpdates';
import OutStanding from '@/components/Desktop/PersonalDashboard/OutStanding';
import topScores from '@/components/Desktop/PersonalDashboard/topScores';

export default {
    name: "PersonalDashboard",
    components: {
        LastUpdates,
        OutStanding,
        topScores
    }
}

</script>