<template>

    <div  class="box flex-none mt-2 shadow-md h-70 relative">
        <div class="box-title">The Essential</div>

        <loader :loading="loading" />
        <div  v-if="dataOut.length > 0" class="h-full w-full">
            <div class="h-full w-full">
                <div class="h-full w-full bg-module">
                    <div  class="w-full h-full flex flex-row">
                        <div @click="go(dataOut[0])" class="cursor-pointer h-full flex-1 min-w-0 p-2 flex flex-col">
                            <div class="h-auto mb-1 flex flex-row justify-between items-center">
                                <span class="text-sm">
                                    {{ dataOut[0].date | moment('DD / MM / yyyy') }}
                                </span>
                            </div>
                            <div v-if="dataOut[0].image" class="h-44 w-full  rounded-lg bg-cover mt-2 shadow-xl relative">
                                <img class="mb-8 w-full h-full rounded-lg object-cover bg-center shadow-xl" :src="imageRoute + dataOut[0].image"/>
                            </div>
                            <div class="h-10 mb-1 mt-2">
                                <span :class="(path !== 'PersonalDashboard') ? 'truncate-2': 'truncate-3'" class="font-semibold text-xl ">{{ dataOut[0].title }}</span>
                            </div>
                            <div class="h-auto mb-1 mt-1 items-center flex flex-row-reverse">
                                <div class="h-auto pl-2 w-1/6 text-center">
                                    <span class="font-semibold text-xs " style="color: #13F2E3">more...</span>
                                </div>
                                <div class="h-auto w-5/6  ">
                                    <p :class="(path === 'home') ? 'truncate-2': null"
                                       class="text-sm text-font-gray group-hover:text-font-light text-justify font-medium truncate-2"
                                       v-html="dataOut[0].body">
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="h-full w-full flex flex-col justify-center items-center">
            <span class="text-xl font-semibold text-font-gray">- No Data -</span>
        </div>
    </div>

</template>

<script>

    import { getNestedRoutes } from '@/router';
    import {state} from '@/store';

    export default {
        name: "TheEssential",
        data() {
            return {
                selected: false,
                dataOut: false,
                loading: false
            }
        },
        methods: {
            load() {
                this.loading = true
                this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Outstanding&resources=Essential', {params: state.globalParams})
                    .then(response => {
                        this.loading = false
                        this.dataOut = response.data
                    })
            },
            go(element) {
                this.$router.push({ name: 'TheEssential', params: { id: element.id } })
            },
            color(type) { return this.$colorsByOptionCommsAndDash(type)}
        },
        computed: {
            path() { return this.$route.path.split('/')[2] },
            imageRoute() { return this.$imageRoute },
            routes() { return getNestedRoutes('desktop.PersonalDashboard') },
        },
        mounted() {
            this.load();
        }
    }

</script>