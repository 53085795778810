<template>
    
    <div v-if="globalParamsPretty.rol.role_type_link !== 'EP'" class=" overflow-hiddnen p-4 flex flex-col relative">
        <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
        <loader :loading="loading" />
            <div class="flex items-center">
                <div class="module-title">Interoperabilidad</div>
                <div class="ml-auto flex">
                    <router-link :to="{ name: 'InterIntra' }" class="header-btn">
                        <i class="mdi mdi-view-split-vertical"></i>
                    </router-link>
                </div>
            </div>

            <div class="flex-1 overflow-hidden flex flex-col justify-around">
                <div class="flex items-center justify-between">
                    <div class="text-font-gray">Purchased Hours</div>
                    <div class="text-xl font-semibold">
                        {{ interintra.purchase_hours_sl || 0 | reduceBigNumbers() }}
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <div class="text-font-gray">Sold Hours</div>
                    <div class="text-xl font-semibold">
                        {{ interintra.sold_hours_sl || 0 | reduceBigNumbers() }}
                    </div>
                </div>
            </div>

        </div>

</template>

<script>
import { state } from '@/store';

export default {
    data() {
        return {
            // effectiveness:0
            loading: false,
            interintra: false
        }
    },
    methods: {
        load() {
            this.loading = true;

            // if(state.rolSelected.role_type_link != 'presidencia'){

            this.axios.get('inter_intra/hours_purchased_sold', {params: this.params}).
                then(response => {
                    this.interintra = response.data.data
            })

            // }
            
            this.loading = false
        }
    },
    computed: {
        globalParamsPretty() { return state.globalParamsPretty },
        userData() { return state.user },
        defaultSelectedRol() {
            return this._.find(this.userData.roles, {
                role_type_id: this.userData.role_default.role_type_id,
                gui_role_specific_id: this.userData.role_default.gui_role_specific_id,
            })
        },
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        },
        seeit(){
            if(state.rolSelected.role_type_link != 'presidencia'){
                return true
            } else {
                return false
            }
        }
    },
    watch:{
        params(){
            this.load();
        }
    },
    mounted() { this.load() }
}
</script>